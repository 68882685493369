<template>    
<div class="row">
    <div class="container">
        <div class="row">
            <div class="d-flex justify-content-between">
                <div>
                    <h3>Hi, {{ curAdmin?.userName }}.</h3> 
                </div>                    
            </div>

            <hr class="divider-line0" />

            <h2>User Settings</h2>
        </div>
        
        <div :class="getNoDataClass(userData)" style="padding-left: 16px; padding-top: 16px;">
            <div class="row" style="width: 100%;">
                <div class="d-flex justify-content-start" v-if="userData.length>0">
                    <el-pagination
                    v-model:current-page="pageIndex"
                    v-model:page-size="pageSize"
                    :page-sizes="[10, 20, 50]"                                                                                                                                 
                    layout="sizes, prev, pager, next, total"
                    :total="totalCount"      
                    @size-change="handlePageSizeChange"                                         
                    @current-change="handlePageIndexChange"
                    />
                </div>
                <div v-if="userData && userData.length<=0" style="text-align: center;">No user data</div>
                <div v-for="user in userData" :key="user?.Id" class="summary">
                    <div class="d-flex justify-content-between" style="background-color: #F0F2F2; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">                     
                      <div class="d-flex justify-content-start" style="align-items: center;">
                        <div style="margin-top: 3px;"><el-icon title="User name"><User /></el-icon></div>
                        <div style="padding-left: 6px;" v-if="user.isActive === false"><el-tag :type="getActiveType(user)">{{ getActiveText(user) }}</el-tag></div>
                        <div style="padding-left: 6px;"><el-tooltip :content="'#'+ user?.id">{{ getUserFirstName(user) }}</el-tooltip></div>                        
                        <div style="padding-left: 8px;"><img :src="'/titles/' + user.rankImage + '.png'" :alt="user.rankTitle" width="24"></div>
                        <div style="padding-left: 4px; font-weight: 600; margin-top: 3px;">{{ user?.rankTitle }}</div>                                                
                      </div>                                           
                      <div class="d-flex justify-content-start">
                        <div style="margin-top: 3px;"><el-icon title="Phone number"><Phone /></el-icon></div>
                        <div style="padding-left: 6px;">{{ user?.phoneNo }}</div>
                      </div>
                      <div class="d-flex justify-content-start">
                        <div style="margin-top: 3px;"><el-icon title="Email"><MessageBox /></el-icon></div>
                        <div style="padding-left: 6px;">{{ user?.emailAddress }}</div>
                      </div>                      
                      <div class="d-flex justify-content-start">
                        <div style="margin-top: 3px;"><el-icon title="Total points"><Wallet /></el-icon></div>
                        <div style="padding-left: 6px; color: firebrick; font-weight: 600;"><el-tooltip :content="getUserPointsDetail(user)">{{ user?.points+user?.rewardPoints }}</el-tooltip></div>                                                
                      </div>
                      <div class="d-flex justify-content-start">
                        <div class="links">
                          <div>                                                      
                              <a href="#" @click.prevent="showHisExpDlg(user)">Exp history</a>                                                                                                            
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-start">
                        <div class="links">
                          <div>                                                      
                              <a href="#" @click.prevent="showHisPaymentDlg(user)">Payment history</a>                                                                                                            
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-start">
                        <div class="links" style="padding-right: 14px;">                                                      
                            <a href="#" @click.prevent="showHisPointDlg(user)">Point history</a>                                                                                                            
                        </div>
                      </div>
                    </div>
                    <div><hr class="divider-line1"/></div>
                    <div class="d-flex justify-content-start" style="align-items: center; padding: 10px; padding-top: 0px; padding-left: 16px; font-size: 14px; color:#333;">                     
                      <div class="row" style="width: 90%;">
                        <el-collapse class="transparent-collapse">
                          <el-collapse-item  name="1">
                            <template #title>
                                <span class="custom-title">Basic Info</span>
                            </template>
                            <div class="row" style="padding-left: 16px;">
                              <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">                                                                
                                <div><el-checkbox v-model="user.emailNotification" label="Email notification" disabled/></div>                               
                                <div class="d-flex justify-content-start">
                                  <div style="margin-top: 3px;"><el-icon title="Birthday"><Calendar /></el-icon></div>                                
                                  <div style="padding-left: 6px;">{{ getUserBirthday(user) }}</div>                
                                </div>
                                <div class="d-flex justify-content-start">
                                  <div style="margin-top: 3px;"><el-icon title="Create time"><Calendar /></el-icon></div>                                        
                                  <div style="padding-right: 30px; padding-left: 6px;">{{ getTime(user?.createTime) }}</div>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between" style="align-items: center; padding-top: 10px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                <div class="d-flex justify-content-start" style="align-items: center;">
                                  <div style="margin-top: 3px;"><el-icon title="Rank"><GoldMedal /></el-icon></div>
                                  <div style="padding-left: 6px; font-weight: 600;">{{ user?.rank }}</div>                                  
                                  <div style="padding-left: 10px;">
                                    <el-tooltip :content="`${user?.exp}/${user?.nextExp}`">
                                      <el-progress                            
                                        :stroke-width="10"              
                                        :percentage="((user?.exp/user?.nextExp)*100).toFixed(1)"      
                                        style="width: 800px;"
                                      >
                                      <template #default>
                                        <span style="color: green; font-weight: bold; font-size: 14px;">{{ ((user?.exp/user?.nextExp)*100).toFixed(0) }}%</span>
                                      </template>
                                      </el-progress>
                                    </el-tooltip>
                                  </div>      
                                  <div style="padding-left: 10px;" v-if="user?.specialAttr>0">
                                    <el-tag type="primary" effect="dark">{{ getUserSpecialAttrName(user?.specialAttr) }}</el-tag>                                    
                                  </div>                                                                     
                                </div>                                                         
                              </div>
                            </div>
                          </el-collapse-item>
                          <el-collapse-item  name="2">
                            <template #title>
                                <span class="custom-title">Property Info</span>
                            </template>
                            <div class="row" style="padding-left: 16px;">
                              <div v-for="address in user?.properties" :key="address?.id" class="d-flex justify-content-start" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; padding-bottom: 14px; font-size: 14px;">
                                <div class="row" style="width: 90%;">
                                <div class="d-flex justify-content-start" style="align-items: center;">
                                  <div style="margin-top: 3px;"><el-icon title="Address"><AddLocation /></el-icon></div>
                                  <div style="padding-left: 6px;" v-if="address?.isConfirmed === false"><el-tag :type="getPropertyConfirmType(address)">{{ getPropertyConfirmText(address) }}</el-tag></div>
                                  <div style="padding-left: 6px;" v-if="address?.isFrequent"><el-tag type="info" effect="dark">Default</el-tag></div>
                                  <div style="padding-left: 6px;"><el-tooltip :content="'#'+address.id">{{ address?.unit }} {{ address?.streetNo }} {{ address?.streetName }} {{ address?.city }}, {{ getTxtProvinceName(address?.provinceId) }} {{ address?.postal }}</el-tooltip></div>                                  
                                  <div style="margin-top: 3px; padding-left: 36px;"><el-icon title="Contacter"><UserFilled /></el-icon></div>
                                  <div style="padding-left: 6px;">{{ address?.firstName }} {{ address?.lastName }}</div>
                                </div>                               
                                <div class="d-flex justify-content-start" style="align-items: center; padding-top: 0px;">
                                  <div><el-icon title="Details"><Memo /></el-icon></div>
                                  <div style="padding-left: 10px;" class="summary">
                                    <div class="d-flex justify-content-start" style="padding-top: 10px; padding-bottom: 10px;">
                                      <div><el-tag :type="getPropertyTagType(address?.buildingType)" effect="plain">{{ $t(getPropertyBuildingTypeText(address?.buildingType)) }}</el-tag></div>
                                      <div style="padding-left: 12px;"><el-tag :type="getPropertyTagType(address?.kitchenType)" effect="plain">{{ $t(getPropertyKitchenText(address?.kitchenType)) }}</el-tag></div>
                                      <div style="padding-left: 12px;"><el-tag :type="getPropertyTagType(address?.bathType)" effect="plain">{{ $t(getPropertyBathText(address?.bathType)) }}</el-tag></div>
                                      <div style="padding-left: 12px;" v-if="address?.hasHalfbath === false"><el-tag type="warning" effect="plain">No half-bath</el-tag></div>
                                    </div>
                                    <div class="d-flex justify-content-start" style="padding-top: 0px; padding-bottom: 10px;">
                                      <div><el-tag :type="getPropertyTagType(address?.areaType)" effect="plain">{{ $t(getPropertyAreaText(address?.areaType)) }}</el-tag></div>
                                      <div style="padding-left: 12px;" v-if="address?.isCornerLot"><el-tag type="warning" effect="plain">Corner lot</el-tag></div>
                                      <div style="padding-left: 12px;"><el-tag :type="getPropertyTagType(address?.driveWayType)" effect="plain">{{ $t(getPropertyDrivewayText(address?.driveWayType)) }}</el-tag></div>
                                      <div style="padding-left: 12px;" v-if="address?.hasNoWalkway"><el-tag type="primary" effect="plain">No walkway</el-tag></div>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-start" style="align-items: center;">
                                  <div class="d-flex justify-content-start links" style="align-items: center; padding-bottom: 10px; padding-top: 10px; padding-right: 30px; font-size: 14px;">
                                    <div style="padding-right: 10px; padding-left: 24px;">                                                      
                                        <a href="#" @click.prevent="showEditProperty(user?.id, address)">Edit address</a>                                                                                                            
                                    </div>
                                    <span style="color:darkgray" v-if="!address?.isFrequent">|</span> 
                                    <div style="padding-right: 10px; padding-left: 10px;" v-if="!address?.isFrequent">                                                      
                                        <a href="#" @click.prevent="setDefaultAddress(user?.id, address?.id)">Set default</a>                                                                                                            
                                    </div>                                                                                                           
                                    <span style="color:darkgray" v-if="address?.isConfirmed === false">|</span>
                                    <div style="padding-right: 10px; padding-left: 10px;" v-if="address?.isConfirmed === false">                                                      
                                        <a href="#" @click.prevent="showConfirmProperty(user?.id, address)">Confirm property</a>                                                                                                            
                                    </div>                                                              
                                  </div>
                                </div>
                                <div><hr class="divider-line1"/></div>
                              </div>
                              </div>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </div>                      
                    </div>
                    <div class="d-flex justify-content-end links" style="padding-right: 40px; padding-top: 10px; padding-bottom: 20px;">
                      <div style="padding-right: 10px;">
                        <a href="#" @click.prevent="showAddProperty(user?.id)">Add new property address</a>                          
                      </div>                      
                      <span style="color:darkgray" v-if="user.isActive === false">|</span> 
                      <div style="padding-left: 10px; padding-right: 10px;" v-if="user.isActive === false">
                        <a href="#" @click.prevent="handleSendActiveMail(user?.id)">Send active email</a>                          
                      </div>
                      <span style="color:darkgray">|</span> 
                      <div style="padding-right: 10px; padding-left: 10px;">                                                      
                          <a href="#" @click.prevent="showConfirmRechargeDlg(user)">Confirm recharge</a>                                                                                                            
                      </div>
                      <span style="color:darkgray">|</span> 
                      <div style="padding-right: 10px; padding-left: 10px;">
                        <a href="#" @click.prevent="showAddRewardPointDlg(user)">Add reward points</a>
                      </div>  
                      <span style="color:darkgray">|</span> 
                      <div style="padding-right: 10px; padding-left: 10px;">
                        <a href="#" @click.prevent="showEditSpecialAttrDlg(user)">Edit special attr</a>
                      </div>
                    </div>
                </div>
            </div>
            <el-dialog v-model="propertyEditElem.showDlg" width="600" :close-on-click-modal="false" destroy-on-close>
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                  <span style="color: #333; font-size: 16px;; font-weight: 600;">{{ getPropertyDlgTitleText() }}</span>
              </div>
              <hr class="divider-line1"/> 
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                <div class="row" style="width: 84%;">
                  <div class="d-flex justify-content-between" style="padding-top: 16px; padding-bottom: 10px;">
                    <div><el-input v-model="propertyEditElem.firstName" style="width: 220px" placeholder="First Name" /></div>
                    <div><el-input v-model="propertyEditElem.lastName" style="width: 220px" placeholder="Last Name" /></div>
                  </div>
                  <hr class="divider-line1"/>
                  <div class="d-flex justify-content-between" style="padding-top: 10px;">
                    <div><el-input v-model="propertyEditElem.unit" style="width: 220px" placeholder="Apt/Unit" /></div>
                    <div><el-input v-model="propertyEditElem.streetNo" style="width: 220px" placeholder="Street Number" /></div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 10px;">
                    <div><el-input v-model="propertyEditElem.streetName" style="width: 450px" placeholder="Street Name" /></div>
                  </div>
                  <div class="d-flex justify-content-between" style="padding-top: 10px;">
                    <div><el-input v-model="propertyEditElem.city" style="width: 140px" placeholder="City/Town" /></div>
                    <div><el-input v-model="propertyEditElem.postal" style="width: 140px" placeholder="Postal/ZIP code" /></div>
                    <div>
                      <el-select
                        v-model="propertyEditElem.provinceId"
                        placeholder="Select Province/Territory"                      
                        style="width: 140px"
                      >
                        <el-option
                          v-for="item in PROVINCES"
                          :key="item.Id"
                          :label="item.name"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 10px;">
                   <el-checkbox v-model="propertyEditElem.markDefault">Mark as default</el-checkbox>
                  </div>                          
                </div>                
              </div>
              <hr class="divider-line1"/>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 20px;">
                <div class="row" style="width: 84%;">
                  <div class="d-flex justify-content-between" style="padding-top: 16px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyEditElem.buildingType"
                        placeholder="Select Building Type"                      
                        style="width: 220px"
                      >
                        <el-option
                          v-for="item in BUILDINGTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                    <div>
                      <el-select
                        v-model="propertyEditElem.kitchenType"
                        placeholder="Select Kitchen Detail"                      
                        style="width: 220px"
                      >
                        <el-option
                          v-for="item in KITCHENTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyEditElem.areaType"
                        placeholder="Select Area Detail"                      
                        style="width: 450px"
                      >
                        <el-option
                          v-for="item in AREATYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyEditElem.bathType"
                        placeholder="Select Bath Detail"                      
                        style="width: 450px"
                      >
                        <el-option
                          v-for="item in BATHTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px;">
                   <el-checkbox v-model="propertyEditElem.hasHalfbath">Including half-bath</el-checkbox>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyEditElem.driveWayType"
                        placeholder="Select Driveway Detail"                      
                        style="width: 450px"
                      >
                        <el-option
                          v-for="item in DRIVEWAYTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px;">
                    <div><el-checkbox v-model="propertyEditElem.isCornerLot">Corner lot</el-checkbox></div>
                    <div style="padding-left: 20px;"><el-checkbox v-model="propertyEditElem.hasNoWalkway">No walkway</el-checkbox></div>
                  </div>
                </div>
              </div>
              <hr class="divider-line1"/>
              <div class="d-flex justify-content-center" style="padding-top: 30px; padding-bottom: 30px;">
                <div>
                  <button :disabled="loading" class="submit-button" @click.prevent="handleAddProperty()"><span v-if="!loading">{{ getPropertyDlgBtnText() }}</span>
                  <span v-else class="spinner"></span></button>
                </div>
              </div>
            </el-dialog>

            <el-dialog v-model="propertyConfirmElem.showDlg" width="600" :close-on-click-modal="false" destroy-on-close>
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                  <span style="color: #333; font-size: 16px;; font-weight: 600;">Confirm Address</span>
              </div>
              <hr class="divider-line1"/>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 20px;">
                <div class="row" style="width: 84%;">
                  <div class="d-flex justify-content-between" style="padding-top: 16px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyConfirmElem.buildingType"
                        placeholder="Select Building Type"                      
                        style="width: 220px"
                      >
                        <el-option
                          v-for="item in BUILDINGTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                    <div>
                      <el-select
                        v-model="propertyConfirmElem.kitchenType"
                        placeholder="Select Kitchen Detail"                      
                        style="width: 220px"
                      >
                        <el-option
                          v-for="item in KITCHENTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyConfirmElem.areaType"
                        placeholder="Select Area Detail"                      
                        style="width: 450px"
                      >
                        <el-option
                          v-for="item in AREATYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyConfirmElem.bathType"
                        placeholder="Select Bath Detail"                      
                        style="width: 450px"
                      >
                        <el-option
                          v-for="item in BATHTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px;">
                   <el-checkbox v-model="propertyConfirmElem.hasHalfbath">Including half-bath</el-checkbox>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 10px;">
                    <div>
                      <el-select
                        v-model="propertyConfirmElem.driveWayType"
                        placeholder="Select Driveway Detail"                      
                        style="width: 450px"
                      >
                        <el-option
                          v-for="item in DRIVEWAYTYPES"
                          :key="item.Id"
                          :label="$t(item.key)"
                          :value="item.Id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" style="padding-top: 0px;">
                    <div><el-checkbox v-model="propertyConfirmElem.isCornerLot">Corner lot</el-checkbox></div>
                    <div style="padding-left: 20px;"><el-checkbox v-model="propertyConfirmElem.hasNoWalkway">No walkway</el-checkbox></div>
                  </div>
                </div>
              </div>
              <hr class="divider-line1"/>
              <div class="d-flex justify-content-center" style="padding-top: 30px; padding-bottom: 30px;">
                <div>
                  <button :disabled="loading" class="submit-button" @click.prevent="handleConfirmProperty()"><span v-if="!loading">Confirm</span>
                  <span v-else class="spinner"></span></button>
                </div>
              </div>
            </el-dialog>         
            
            <el-dialog v-model="userConfirmRechargeElem.showDlg" width="600" :close-on-click-modal="false" destroy-on-close>
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                  <span style="color: #333; font-size: 16px;; font-weight: 600;">Confirm Recharge</span>
              </div>
              <hr class="divider-line1"/>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 10px;">
                <div class="row" style="width: 100%;">                                    
                  <div class="summary" style="padding-left: 16px;">
                    <div class="d-flex justify-content-between" style="align-items: center; padding-left: 4px;">
                      <div class="d-flex justify-content-start" style="padding-top: 10px;">
                        <div style="margin-top: 3px;"><el-icon title="Total points"><Wallet /></el-icon></div>
                        <div style="padding-left: 6px; color: firebrick; font-weight: 600;"><el-tooltip :content="getUserPointsDetail(userConfirmRechargeElem.curUser)">{{ userConfirmRechargeElem.curUser?.points+userConfirmRechargeElem.curUser?.rewardPoints }}</el-tooltip></div>                                              
                      </div>
                      <div class="d-flex justify-content-start" style="padding-top: 10px;">
                        <div style="margin-top: 3px;"><el-icon title="User name"><User /></el-icon></div>
                        <div style="padding-left: 6px;"><el-tooltip :content="'#'+ userConfirmRechargeElem.curUser?.id">{{ getUserFirstName(userConfirmRechargeElem.curUser) }}</el-tooltip></div>                        
                      </div>                                            
                      <div class="d-flex justify-content-start" style="padding-top: 10px; padding-right: 16px;">
                        <div style="margin-top: 3px;"><el-icon title="Email"><MessageBox /></el-icon></div>
                        <div style="padding-left: 6px;">{{ userConfirmRechargeElem.curUser?.emailAddress }}</div>                        
                      </div>
                    </div>
                    <div class="d-flex justify-content-start" style="align-items: center; padding-top: 10px; padding-bottom: 10px;">
                      <div class="d-flex justify-content-start">
                        <div><img :src="'/titles/' + userConfirmRechargeElem.curUser?.rankImage + '.png'" :alt="userConfirmRechargeElem.curUser.rankTitle" width="24"></div>
                        <div style="padding-left: 4px; font-weight: 600; margin-top: 3px;">{{ userConfirmRechargeElem.curUser?.rankTitle }}</div>
                      </div>
                      <div class="d-flex justify-content-start" style="padding-left: 20px; margin-top: 5px;">
                        <div style="margin-top: 3px;"><el-icon title="Rank"><GoldMedal /></el-icon></div>
                        <div style="padding-left: 6px; font-weight: 600;">{{ userConfirmRechargeElem.curUser?.rank }}</div>                                  
                        <div style="padding-left: 10px; margin-top: 2px;">
                          <el-tooltip :content="`${userConfirmRechargeElem.curUser?.exp}/${userConfirmRechargeElem.curUser?.nextExp}`">
                            <el-progress                            
                              :stroke-width="10"              
                              :percentage="((userConfirmRechargeElem.curUser?.exp/userConfirmRechargeElem.curUser?.nextExp)*100).toFixed(1)"      
                              style="width: 300px;"
                            >
                            <template #default>
                              <span style="color: green; font-weight: bold; font-size: 14px;">{{ ((userConfirmRechargeElem.curUser?.exp/userConfirmRechargeElem.curUser?.nextExp)*100).toFixed(0) }}%</span>
                            </template>
                            </el-progress>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="d-flex justify-content-center" style="padding-top: 10px; align-items: center;">
                <div class="d-flex justify-content-start" style="align-items: center;">                  
                  <div>
                    <el-select
                      v-model="userConfirmRechargeElem.paymentMethod"
                      placeholder="Select Payment Method"                      
                      style="width: 200px"
                    >
                      <el-option
                        v-for="item in PAYMENTMETHODS"
                        :key="item.Id"
                        :label="item.name"
                        :value="item.Id"
                      />
                    </el-select>
                  </div>
                  <div style="padding-left: 6px;">                    
                    $&nbsp;<el-input-number style="width: 130px;" v-model="userConfirmRechargeElem.amount" :min="0" :max="99999" :step="50"/>                    
                  </div>                  
                </div>                
              </div>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 20px; align-items: center;">
                <div class="d-flex justify-content-start" style="align-items: center;">
                  <div>
                    <el-input v-model="userConfirmRechargeElem.comment" style="width: 350px" placeholder="Comment" />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 20px; align-items: center;">
                <div class="d-flex justify-content-start" style="align-items: center;">
                  <div>Add reward percent(%):</div>
                  <div style="padding-left: 10px;">
                    <el-input-number style="width: 110px;" v-model="userConfirmRechargeElem.rewardPercent" :min="0" :max="100"/>
                  </div>
                </div>
              </div>              
              <div class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 20px;">
                <div>
                  <button :disabled="loading" class="submit-button" @click.prevent="handleConfirmPayment"><span v-if="!loading">Confirm Payment</span>
                  <span v-else class="spinner"></span></button>
                </div>
              </div>              
            </el-dialog>
            
            <el-dialog v-model="hisPointElem.showDlg" width="600" :title="`Point Change Histories`" :close-on-click-modal="false" destroy-on-close>
              <el-table :data="hisPointElem.hisData">                                                
                <el-table-column property="id" label="Id" width="50" />
                <el-table-column label="Amount" width="120">            
                  <template #default="scope">
                    <span :class="getAmountColor(scope.row.paymentId)">{{scope.row.amount}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Time" width="180">
                  <template #default="scope">
                    <span>{{getAmountTime(scope.row.strOccurredTime)}}</span>
                  </template>                  
                </el-table-column>
                <el-table-column property="isReward" label="IsReward" width="90" />
                <el-table-column label="Operator" width="100">
                  <template #default="scope">
                    <span>{{getAmountOpt(scope.row.optRole)}}</span>
                  </template> 
                </el-table-column>
              </el-table>
            </el-dialog>

            <el-dialog v-model="hisExpElem.showDlg" width="600" :title="`Exp Change Histories`" :close-on-click-modal="false" destroy-on-close>
              <el-table :data="hisExpElem.hisData">
                <el-table-column property="id" label="Id" width="50" />
                <el-table-column property="amount" label="Amount" width="120" />                                            
                <el-table-column label="Time" width="180">
                  <template #default="scope">
                    <span>{{getAmountTime(scope.row.strOccurredTime)}}</span>
                  </template>                  
                </el-table-column>                
                <el-table-column label="Operator" width="100">
                  <template #default="scope">
                    <span>{{getAmountOpt(scope.row.optRole)}}</span>
                  </template> 
                </el-table-column>
              </el-table>
            </el-dialog>

            <el-dialog v-model="hisPaymentElem.showDlg" width="1000" :title="`Payment Histories`" :close-on-click-modal="false" destroy-on-close>
              <el-table :data="hisPaymentElem.hisData">
                <el-table-column property="id" label="Id" width="50" />
                <el-table-column property="invoiceNo" label="InvoiceNo" width="180" />
                <el-table-column label="SubTotal" width="120">
                  <template #default="scope">
                    <span>${{ scope.row.subtotalAmount.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Total" width="120">
                  <template #default="scope">
                    <span>${{ scope.row.totalAmount.toFixed(2) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Time" width="180">
                  <template #default="scope">
                    <span>{{getAmountTime(scope.row.strPayTime)}}</span>
                  </template>                  
                </el-table-column>
                <el-table-column label="Pay method" width="120">
                  <template #default="scope">
                    <span>{{getPaymentMethodText(scope.row.payMethod)}}</span>
                  </template>
                </el-table-column>
                <el-table-column property="comment" label="Comment" width="200" />
              </el-table>
            </el-dialog>  

            <el-dialog v-model="addRewardPointElem.showDlg" width="600" :close-on-click-modal="false" destroy-on-close>
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                  <span style="color: #333; font-size: 16px;; font-weight: 600;">Add Reward Point</span>
              </div>
              <hr class="divider-line1"/>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 10px;">
                <div class="row" style="width: 100%;">                                    
                  <div class="summary" style="padding-left: 16px;">
                    <div class="d-flex justify-content-between" style="align-items: center; padding-left: 4px;">
                      <div class="d-flex justify-content-start" style="padding-top: 10px;">
                        <div style="margin-top: 3px;"><el-icon title="Total points"><Wallet /></el-icon></div>
                        <div style="padding-left: 6px; color: firebrick; font-weight: 600;"><el-tooltip :content="getUserPointsDetail(addRewardPointElem.curUser)">{{ addRewardPointElem.curUser?.points+addRewardPointElem.curUser?.rewardPoints }}</el-tooltip></div>                                              
                      </div>
                      <div class="d-flex justify-content-start" style="padding-top: 10px;">
                        <div style="margin-top: 3px;"><el-icon title="User name"><User /></el-icon></div>
                        <div style="padding-left: 6px;"><el-tooltip :content="'#'+ addRewardPointElem.curUser?.id">{{ getUserFirstName(addRewardPointElem.curUser) }}</el-tooltip></div>                        
                      </div>                                            
                      <div class="d-flex justify-content-start" style="padding-top: 10px; padding-right: 16px;">
                        <div style="margin-top: 3px;"><el-icon title="Email"><MessageBox /></el-icon></div>
                        <div style="padding-left: 6px;">{{ addRewardPointElem.curUser?.emailAddress }}</div>                        
                      </div>
                    </div>
                    <div class="d-flex justify-content-start" style="align-items: center; padding-top: 10px; padding-bottom: 10px;">
                      <div class="d-flex justify-content-start">
                        <div><img :src="'/titles/' + addRewardPointElem.curUser?.rankImage + '.png'" :alt="addRewardPointElem.curUser.rankTitle" width="24"></div>
                        <div style="padding-left: 4px; font-weight: 600; margin-top: 3px;">{{ addRewardPointElem.curUser?.rankTitle }}</div>
                      </div>
                      <div class="d-flex justify-content-start" style="padding-left: 20px; margin-top: 5px;">
                        <div style="margin-top: 3px;"><el-icon title="Rank"><GoldMedal /></el-icon></div>
                        <div style="padding-left: 6px; font-weight: 600;">{{ addRewardPointElem.curUser?.rank }}</div>                                  
                        <div style="padding-left: 10px; margin-top: 2px;">
                          <el-tooltip :content="`${addRewardPointElem.curUser?.exp}/${addRewardPointElem.curUser?.nextExp}`">
                            <el-progress                            
                              :stroke-width="10"              
                              :percentage="((addRewardPointElem.curUser?.exp/addRewardPointElem.curUser?.nextExp)*100).toFixed(1)"      
                              style="width: 300px;"
                            >
                            <template #default>
                              <span style="color: green; font-weight: bold; font-size: 14px;">{{ ((addRewardPointElem.curUser?.exp/addRewardPointElem.curUser?.nextExp)*100).toFixed(0) }}%</span>
                            </template>
                            </el-progress>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="d-flex justify-content-center" style="padding-top: 6px; align-items: center;">
                <div style="padding-left: 6px;">                    
                    Points: <el-input-number style="width: 180px;" v-model="addRewardPointElem.amount" :min="0" :max="9999999" :step="500"/>                    
                  </div>
              </div>
              <div class="d-flex justify-content-center" style="padding-top: 30px; padding-bottom: 20px;">
                <div>
                  <button :disabled="loading" class="submit-button" @click.prevent="handleAddRewardPoint"><span v-if="!loading">Add Points</span>
                  <span v-else class="spinner"></span></button>
                </div>
              </div>
            </el-dialog>

            <el-dialog v-model="editSpecialAttrElem.showDlg" width="600" :close-on-click-modal="false" destroy-on-close>
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                  <span style="color: #333; font-size: 16px;; font-weight: 600;">Edit Special Attr</span>                  
              </div>             
              <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                <div style="width: 130px;">
                  <el-select v-model="editSpecialAttrElem.curUser.specialAttr" placeholder="Select Special Attr">
                    <el-option
                      v-for="item in USERSPECIALATTRS"
                      :key="item.Id"
                      :label="item.name"
                      :value="item.Id"
                    />
                  </el-select>
                </div>
              </div>
              <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 20px;">
                <div>
                  <button :disabled="loading" class="submit-button" @click.prevent="handleEditSpecialAttr"><span v-if="!loading">Confirm</span>
                  <span v-else class="spinner"></span></button>
                </div>
              </div>
            </el-dialog>
        </div>        
    </div>    
</div>  

</template>
    

<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { onMounted,ref,getCurrentInstance,computed } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/apiClient';
import { ElLoading } from 'element-plus'
import { MONTHS,PROVINCES,BUILDINGTYPES, KITCHENTYPES, BATHTYPES, AREATYPES, DRIVEWAYTYPES, PAYMENTMETHODS,USERSPECIALATTRS } from '@/constants';

const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const toast = useToast();

const curAdmin = store.getters.admin;

const errors = ref({}); 
const loading = ref(false);
const userData = ref([]);
const pageIndex = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const propertyEditElem = ref({
  editId: 0,
  showDlg: false,

  userId: 0,
  firstName: '',
  lastName: '',
  unit: '',
  streetNo: '',
  streetName: '',
  city: '',
  postal: '',
  provinceId: '',
  markDefault: false,
  buildingType: '',
  kitchenType: '',
  areaType: '',
  bathType: '',
  hasHalfbath: false,
  driveWayType: '',
  isCornerLot: false,
  hasNoWalkway: false,
});
const propertyConfirmElem = ref({
  editId: 0,
  userId: 0,
  showDlg: false,  

  buildingType: '',
  kitchenType: '',
  areaType: '',
  bathType: '',
  hasHalfbath: false,
  driveWayType: '',
  isCornerLot: false,
  hasNoWalkway: false,
});
const userConfirmRechargeElem = ref({
  curUser: {},
  showDlg: false,

  paymentMethod: '',
  rewardPercent: 0,
  comment: '',
  amount: 0,
});
const hisPointElem = ref({  
  showDlg: false,

  hisData: [],
});
const hisExpElem = ref({  
  showDlg: false,

  hisData: []
});
const hisPaymentElem = ref({
  showDlg: false,

  hisData: []
});
const addRewardPointElem = ref({
  curUser: {},
  showDlg: false,
  
  amount: 0,
});
const editSpecialAttrElem = ref({
  curUser: {},
  showDlg: false,  
});


function getUserSpecialAttrName(attr) {
  if(attr === 1) {
    return 'VIP';    
  }
  else {
    return '';
  }
}

function getNoDataClass(data) {    
    if(data.length<=0) {
        return 'd-flex justify-content-center';
    } else {
        return 'd-flex justify-content-start';
    }
}

function getUserFirstName(user) {
  if(!user?.firstName && !user?.lastName) 
    return 'Unset';
  else 
    return `${user?.firstName} ${user?.lastName}`;
}

function getUserBirthday(user) {
  const birthday = new Date(user?.birthday);

  if(birthday.getFullYear()>1900)
    return `${MONTHS[birthday.getMonth()]}.${birthday.getDate()}, ${birthday.getFullYear()}`;
  else
    return 'Unset';
}

function getActiveText(user) {
  if(user?.isActive)
    return 'Actived';
  else
    return 'Inactived';
}

function getActiveType(user) {
  if(user?.isActive)
    return 'primary';
  else
    return 'info';
}

function getPropertyConfirmText(address) {
  if(address?.isConfirmed)
    return 'Confirmed';
  else
    return 'Unconfirmed';
}

function getPropertyConfirmType(address) {
  if(address?.isConfirmed)
    return 'primary';
  else
    return 'info';
}

function getPropertyBuildingTypeText(typeId) {
  if(typeId > 0) {
    const item = BUILDINGTYPES.find(it=>it.Id === typeId);
    return item ? item.key : 'emptyKey';
  } else {
    return 'Building Type Unset';
  }
}

function getPropertyTagType(typeId) {
  if(typeId > 0) 
    return 'success';
  else
    return 'info';
}

function getPropertyKitchenText(typeId) {
  if(typeId > 0) {
    const item = KITCHENTYPES.find(it=>it.Id === typeId);
    return item ? item.key : 'emptyKey';
  } else {
    return 'Kitchen Detail Unset';
  }
}

function getPropertyBathText(typeId) {
  if(typeId > 0) {
    const item = BATHTYPES.find(it=>it.Id === typeId);
    return item ? item.key : 'emptyKey';
  } else {
    return 'Bathroom Detail Unset';
  }
}

function getPropertyAreaText(typeId) {
  if(typeId > 0) {
    const item = AREATYPES.find(it=>it.Id === typeId);
    return item ? item.key : 'emptyKey';
  } else {
    return 'Area Detail Unset';
  }
}

function getPropertyDrivewayText(typeId) {
  if(typeId > 0) {
    const item = DRIVEWAYTYPES.find(it=>it.Id === typeId);
    return item ? item.key : 'emptyKey';
  } else {
    return 'Driveway Detail Unset';
  }
}



function getTime(strDate) {
  const date = new Date(strDate);
  return `${MONTHS[date.getMonth()]}.${date.getDate()}, ${date.getFullYear()}`;
}

function getUserPointsDetail(user) {
  return `${user?.points}+${user?.rewardPoints}`;
}

function getTxtProvinceName(provinceId) {
    return computed(() => {
    const item = PROVINCES.find(item => item.Id === provinceId);
    return item ? item.name : '';
  });  
}


async function handlePageSizeChange() {
    await ReLoadUserData();
}

async function handlePageIndexChange() {
    await ReLoadUserData();
}


async function loadUserData(pageIndex, pageSize) {

    const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 
    try {
        const response = await apiClient.post('/Staff/getAdminUserData', {
            curPageIndex: pageIndex,
            pageSize: pageSize,
        });

        if(response.data) {
            userData.value = response.data.userData;
            totalCount.value = response.data.totalCount;
        }

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    } finally {
      isLoading.close();
    }
}


async function ReLoadUserData() {
    await loadUserData(pageIndex.value, pageSize.value);
}


function getPropertyDlgTitleText() {
  if(propertyEditElem.value.editId>0) {
    return 'Edit Property Address';
  } else {
    return 'Add a New Property Address';
  }
}

function getPropertyDlgBtnText() {
  if(propertyEditElem.value.editId>0) {
    return 'Save Property';
  } else {
    return 'Add Property';
  }
}

function showAddProperty(userId) {
  propertyEditElem.value.editId = 0;
  propertyEditElem.value.showDlg = true;

  propertyEditElem.value.userId = userId;
  propertyEditElem.value.firstName = '';
  propertyEditElem.value.lastName = '';
  propertyEditElem.value.unit = '';
  propertyEditElem.value.streetNo = '';
  propertyEditElem.value.streetName = '';
  propertyEditElem.value.city = '';
  propertyEditElem.value.postal = '';
  propertyEditElem.value.provinceId = '';
  propertyEditElem.value.markDefault = false;
  propertyEditElem.value.buildingType = '';
  propertyEditElem.value.kitchenType = '';
  propertyEditElem.value.areaType = '';
  propertyEditElem.value.bathType = '';
  propertyEditElem.value.hasHalfbath = false;
  propertyEditElem.value.driveWayType = '';
  propertyEditElem.value.isCornerLot = false;
  propertyEditElem.value.hasNoWalkway = false;
}

function showEditProperty(userId, property) {
  propertyEditElem.value.editId = property.id;
  propertyEditElem.value.showDlg = true;

  propertyEditElem.value.userId = userId;
  propertyEditElem.value.firstName = property.firstName;
  propertyEditElem.value.lastName = property.lastName;
  propertyEditElem.value.unit = property.unit;
  propertyEditElem.value.streetNo = property.streetNo;
  propertyEditElem.value.streetName = property.streetName;
  propertyEditElem.value.city = property.city;
  propertyEditElem.value.postal = property.postal;
  propertyEditElem.value.provinceId = property.provinceId>0?property.provinceId:'';
  propertyEditElem.value.markDefault = property.isFrequent;
  propertyEditElem.value.buildingType = property.buildingType>0?property.buildingType:'';
  propertyEditElem.value.kitchenType = property.kitchenType>0?property.kitchenType:'';
  propertyEditElem.value.areaType = property.areaType>0?property.areaType:'';
  propertyEditElem.value.bathType = property.bathType>0?property.bathType:'';
  propertyEditElem.value.hasHalfbath = property.hasHalfbath;
  propertyEditElem.value.driveWayType = property.driveWayType>0?property.driveWayType:'';
  propertyEditElem.value.isCornerLot = property.isCornerLot;
  propertyEditElem.value.hasNoWalkway = property.hasNoWalkway;
}


async function handleAddProperty() {

  loading.value = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    if(propertyEditElem.value.editId>0) {
      const response = await apiClient.post('/Staff/saveAdminUserProperty', {
        addressId: propertyEditElem.value.editId,
        userId: propertyEditElem.value.userId,
        firstName: propertyEditElem.value.firstName,
        lastName: propertyEditElem.value.lastName,
        unit: propertyEditElem.value.unit,
        streetNo: propertyEditElem.value.streetNo,
        streetName: propertyEditElem.value.streetName,
        city: propertyEditElem.value.city,
        postal: propertyEditElem.value.postal,
        provinceId: Number(propertyEditElem.value.provinceId),
        isDefault: propertyEditElem.value.markDefault,
        buildingType: Number(propertyEditElem.value.buildingType),
        kitchenType: Number(propertyEditElem.value.kitchenType),
        areaType: Number(propertyEditElem.value.areaType),
        bathType: Number(propertyEditElem.value.bathType),
        hasHalfbath: propertyEditElem.value.hasHalfbath,
        driveWayType: Number(propertyEditElem.value.driveWayType),
        isCornerLot: propertyEditElem.value.isCornerLot,
        hasNoWalkway: propertyEditElem.value.hasNoWalkway,
      });

      if(response.data) {
        toast.info(response.data.msg);
      }      

    } else {
      const response = await apiClient.post('/Staff/addAdminUserProperty', {
        userId: propertyEditElem.value.userId,
        firstName: propertyEditElem.value.firstName,
        lastName: propertyEditElem.value.lastName,
        unit: propertyEditElem.value.unit,
        streetNo: propertyEditElem.value.streetNo,
        streetName: propertyEditElem.value.streetName,
        city: propertyEditElem.value.city,
        postal: propertyEditElem.value.postal,
        provinceId: Number(propertyEditElem.value.provinceId),
        isDefault: propertyEditElem.value.isDefault,
        buildingType: Number(propertyEditElem.value.buildingType),
        kitchenType: Number(propertyEditElem.value.kitchenType),
        areaType: Number(propertyEditElem.value.areaType),
        bathType: Number(propertyEditElem.value.bathType),
        hasHalfbath: propertyEditElem.value.hasHalfbath,
        driveWayType: Number(propertyEditElem.value.driveWayType),
        isCornerLot: propertyEditElem.value.isCornerLot,
        hasNoWalkway: propertyEditElem.value.hasNoWalkway,
      });

      if(response.data) {
        toast.info(response.data.msg);
      }
    }

    propertyEditElem.value.showDlg = false;
    
    await loadUserData(pageIndex.value, pageSize.value);

  } catch(error) {
      if(error.response && error.response.data && error.response.status === 400) {                                                              
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
      } else {                
      if(error.response && error.response.status == 401) {
          toast.error('Your session has expired, please login again.');           
          store.dispatch('logoutAdmin');
          router.push('/admin');
      } else {
          toast.error(error.code);                    
      }                                                            
      }
    } finally {
      loading.value = false;
      isLoading.close();
    }  
}


async function setDefaultAddress(userId, addressId) {
  if(confirm("Are you sure you want to set this as your default address?")) {

    const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

    try {
      const response = await apiClient.post('/Staff/setAdminDefaultAddress', {
        userId: userId,
        addressId: addressId,
      });

      if(response && response.data) {
        toast.success(response.data.msg);
      }

      await loadUserData(pageIndex.value, pageSize.value);

    } catch(error) {
      if(error.response && error.response.data && error.response.status === 400) {                                                              
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
      } else {                
      if(error.response && error.response.status == 401) {
          toast.error('Your session has expired, please login again.');           
          store.dispatch('logoutAdmin');
          router.push('/admin');
      } else {
          toast.error(error.code);                    
      }                                                            
      }
    } finally {      
      isLoading.close();
    }
  }
}


function showConfirmProperty(userId, address) {
  propertyConfirmElem.value.editId = address.id;
  propertyConfirmElem.value.userId = userId;
  propertyConfirmElem.value.showDlg = true;

  propertyConfirmElem.value.buildingType = address?.buildingType>0?address?.buildingType:'';
  propertyConfirmElem.value.kitchenType = address?.kitchenType?address?.kitchenType:'';  
  propertyConfirmElem.value.areaType = address?.areaType?address?.areaType:'';
  propertyConfirmElem.value.bathType = address?.bathType?address?.bathType:'';
  propertyConfirmElem.value.hasHalfbath = address?.hasHalfbath;
  propertyConfirmElem.value.driveWayType = address?.driveWayType?address?.driveWayType:'';
  propertyConfirmElem.value.isCornerLot = address?.isCornerLot;
  propertyConfirmElem.value.hasNoWalkway = address?.hasNoWalkway;
}


async function handleConfirmProperty() {
  
  loading.value = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/confirmPropertyDetails', {
      addressId: propertyConfirmElem.value.editId,
      buildingType: Number(propertyConfirmElem.value.buildingType),
      kitchenType: Number(propertyConfirmElem.value.kitchenType),
      areaType: Number(propertyConfirmElem.value.areaType),
      bathType: Number(propertyConfirmElem.value.bathType),
      hasHalfbath: propertyConfirmElem.value.hasHalfbath,
      driveWayType: Number(propertyConfirmElem.value.driveWayType),
      isCornerLot: propertyConfirmElem.value.isCornerLot,
    });

    if(response.data) {
      toast.info(response.data.msg);
    }

    propertyConfirmElem.value.showDlg = false;

    await loadUserData(pageIndex.value, pageSize.value);

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {
    loading.value = false;
    isLoading.close();
  }

}


async function handleSendActiveMail(userId) {
  if(confirm("Are you sure you want to send active email for this user?")) {

    const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

    try {
      const response = await apiClient.post('/Staff/sendUserActiveMail', {
        userId: userId,
      });

      if(response && response.data) {
        toast.success(response.data.msg);
      }

    } catch(error) {
      if(error.response && error.response.data && error.response.status === 400) {                                                              
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
      } else {                
      if(error.response && error.response.status == 401) {
          toast.error('Your session has expired, please login again.');           
          store.dispatch('logoutAdmin');
          router.push('/admin');
      } else {
          toast.error(error.code);                    
      }                                                            
      }
    } finally {      
      isLoading.close();
    }
  }
}


function showEditSpecialAttrDlg(user) {
  editSpecialAttrElem.value.curUser = user;
  editSpecialAttrElem.value.showDlg = true;  
}


function showAddRewardPointDlg(user) {
  addRewardPointElem.value.curUser = user;
  addRewardPointElem.value.showDlg = true;

  addRewardPointElem.value.amount = 0;
}


function showConfirmRechargeDlg(user) {
  userConfirmRechargeElem.value.curUser = user;
  userConfirmRechargeElem.value.showDlg = true;

  userConfirmRechargeElem.value.paymentMethod = '';
  userConfirmRechargeElem.value.rewardPercent = 0;
  userConfirmRechargeElem.value.comment = '';
  userConfirmRechargeElem.value.amount = 0;
}


async function handleEditSpecialAttr() {
  loading.value = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/editUserSpecialAttr', {
      userId: editSpecialAttrElem.value.curUser.id,
      specialAttr: editSpecialAttrElem.value.curUser.specialAttr,
    });

    if(response.data) {
      toast.success(response.data.msg);
    }

    editSpecialAttrElem.value.showDlg = false;

    await loadUserData(pageIndex.value, pageSize.value);

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {
    loading.value = false;
    isLoading.close();
  }

}

async function handleAddRewardPoint() {
  loading.value = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/addUserRewardPoint', {
      userId: addRewardPointElem.value.curUser.id,            
      amount: addRewardPointElem.value.amount,      
    });

    if(response.data) {
      toast.success(response.data.msg);
    }

    await loadUserData(pageIndex.value, pageSize.value);
    addRewardPointElem.value.curUser = response.data.newUser;
    addRewardPointElem.value.amount = 0;

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {
    loading.value = false;
    isLoading.close();
  }
}

async function handleConfirmPayment() {

  loading.value = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/confrmUserPayment', {
      userId: userConfirmRechargeElem.value.curUser.id,
      paymentMethod: Number(userConfirmRechargeElem.value.paymentMethod),
      paidAmount: userConfirmRechargeElem.value.amount,
      rewardPercent: userConfirmRechargeElem.value.rewardPercent,
      comment: userConfirmRechargeElem.value.comment,
    });

    if(response.data) {
      toast.success(response.data.msg);
    }

    await loadUserData(pageIndex.value, pageSize.value);
    userConfirmRechargeElem.value.curUser = response.data.newUser;
    userConfirmRechargeElem.value.amount = 0;

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {
    loading.value = false;
    isLoading.close();
  }
}


async function showHisPointDlg(user) {  
  hisPointElem.value.showDlg = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/getAdminUserPointHistory', {
      userId: user.id,
    });

    if(response.data) {
      hisPointElem.value.hisData = response.data.hisPoints;
    }

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {    
    isLoading.close();
  }

}

function getAmountColor(paymentId) {
  if(paymentId === 0) {
    return 'amountred';
  } else {
    return 'amountblue';
  }
}

function getAmountTime(time) {
  const newTime = new Date(time);

  return `${newTime.getMonth()+1}.${newTime.getDate()}, ${newTime.getFullYear()} ${newTime.getHours()}:${newTime.getMinutes()}`;
}

function getAmountOpt(opt) {
  if(opt === 0)
    return 'System';
  else if(opt === 1) 
    return 'Customer';
  else if(opt === 2)
    return 'Staff';
  else if(opt === 3)
    return 'Admin';
}


async function showHisExpDlg(user) {
  hisExpElem.value.showDlg = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/getAdminUserExpHistory', {
      userId: user.id,
    });

    if(response.data) {
      hisExpElem.value.hisData = response.data.hisExps;
    }

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {    
    isLoading.close();
  }
}


async function showHisPaymentDlg(user) {
  hisPaymentElem.value.showDlg = true;

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const response = await apiClient.post('/Staff/getAdminUserPayments', {
      userId: user.id,
    });

    if(response.data) {
      hisPaymentElem.value.hisData = response.data.hisPayments;
    }

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                                                              
      errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
    } else {                
    if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logoutAdmin');
        router.push('/admin');
    } else {
        toast.error(error.code);                    
    }                                                            
    }
  } finally {    
    isLoading.close();
  }
}


function getPaymentMethodText(paymentMethod) {
  const item = PAYMENTMETHODS.find(it=> it.Id === paymentMethod);
  return item ? item.name : ''; 
}



onMounted(() =>{    

if(!store.getters.isAdminLogin) {
    router.push('/admin');
    return;
}

ReLoadUserData();

});

</script>


<style scoped>
.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;  
}

.divider-line2 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 6px;
  margin-top: 10px;
  width: 94%;
}


h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {  
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 14px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 96%;
}

.custom-title {
  font-weight: bold; /* Makes the title bold */
  font-size: 14px;
}

.transparent-collapse {
  background-color: transparent !important; /* Set the background to transparent */
  width: 100%;
  padding-top: 10px; /* Padding around the collapse */
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border: none;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  
}

button:hover {
  background-color: #0056b3;
}

.amountblue {
  color: blue;
}
.amountred {
  color: red;
}

</style>
    