export const PROVINCES = [
    { Id: 1, name: 'AB'},
    { Id: 2, name: 'BC'},
    { Id: 3, name: 'MB'},
    { Id: 4, name: 'NB'},
    { Id: 5, name: 'NL'},
    { Id: 6, name: 'NS'},
    { Id: 7, name: 'ON'},
    { Id: 8, name: 'PE'},
    { Id: 9, name: 'QC'},
    { Id: 10, name: 'SK'},
    { Id: 11, name: 'NT'},
    { Id: 12, name: 'YT'},
    { Id: 13, name: 'NU'}
];

export const PROPERTYCONDS = [
    { Id: 1, name: 'BuildingType' },
    { Id: 2, name: 'Area' },
    { Id: 4, name: 'Kitchen' },
    { Id: 5, name: 'Bathroom' },
    { Id: 7, name: 'Driveway' }    
];

export const COUNTINGTYPES = [
    { Id: 1, name: 'ByMinute'},
    { Id: 2, name: 'ByPercent'},
    { Id: 3, name: 'ByReduction'}
];

export const BUILDINGTYPES = [
    { Id: 1, key: 'buildingTypes.house'},
    { Id: 2, key: 'buildingTypes.condo'},
    { Id: 3, key: 'buildingTypes.commercial'},
];

export const AREATYPES = [
    { Id: 2, key: 'areaTypes.fewer1000'},
    { Id: 1, key: 'areaTypes.area1000To2300'},
    { Id: 3, key: 'areaTypes.area2300To4000'},
    { Id: 4, key: 'areaTypes.over4000'},
];

export const BATHTYPES = [
    { Id: 1, key: 'bathTypes.fewer4'},
    { Id: 2, key: 'bathTypes.bath5To6'},
    { Id: 3, key: 'bathTypes.over6'},
];

export const KITCHENTYPES = [
    { Id: 2, key: 'kitchenTypes.noKitchen' },
    { Id: 1, key: 'kitchenTypes.kitchen1' },
    { Id: 3, key: 'kitchenTypes.kitchen2' },
    { Id: 4, key: 'kitchenTypes.over2' },
];

export const DRIVEWAYTYPES = [
    { Id: 2, key: 'drivewayTypes.noGarage' },
    { Id: 1, key: 'drivewayTypes.standard' },
    { Id: 3, key: 'drivewayTypes.lagreThanStandard' },
    { Id: 4, key: 'drivewayTypes.lagreThan4' },
];

export const SERVICECATALOG = [
    { Id: 1, key: 'servicecatalog.houseCleaning'},
    { Id: 2, key: 'servicecatalog.lawnCare'},
    { Id: 3, key: 'servicecatalog.snowRemoval'},
];

export const ORDERCHANGEDACTIONS = [
    { Id: 1, key: 'orderAction.reschedule'},
    { Id: 2, key: 'orderAction.addInstructions'},
    { Id: 3, key: 'orderAction.cancel'},
    { Id: 4, key: 'orderAction.completed'},
    { Id: 10, key: 'orderAction.confirmed'},
    { Id: 11, key: 'orderAction.inService'}
];

export const ORDERMODIFICATIONROLES = [
    { Id: 0, name: 'System'},
    { Id: 1, name: 'Customer'},
    { Id: 2, name: 'Staff'},
    { Id: 3, name: 'Admin'},
];

export const PAYMENTMETHODS = [
    { Id: 1, name: 'Cash' },
    { Id: 2, name: 'ETransfer' },
    { Id: 3, name: 'Cheque' },
    { Id: 4, name: 'DebitCard' },
    { Id: 5, name: 'CreditCard' }
];

export const PERIODTYPES = [    
    { Id: 1, name: 'Monthly'},
    { Id: 2, name: 'Seasonal'},
];

export const SERVICEDETAILTYPES = [
    { Id: 0, name: 'None'}, 
    { Id: 111, name: 'CarpetCleaning'}
];

export const USERSPECIALATTRS = [
    {Id: 0, name: 'None'},
    {Id: 1, name: 'VIP'}
];

export const WEEKDAYS = [
    'weekDays.sunday', 
    'weekDays.monday', 
    'weekDays.tuesday', 
    'weekDays.wednesday', 
    'weekDays.thursday', 
    'weekDays.friday', 
    'weekDays.saturday'
];

export const MONTHS = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep',
    'Oct', 'Nov', 'Dec'
];

export const ORDERSTATUS = [
    'orderStatus.pending',
    'orderStatus.confirmed',
    'orderStatus.inService',
    'orderStatus.completed',
    'orderStatus.cancelled',
    'orderStatus.disputed'
];


